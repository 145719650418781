/**
 * @file headで読み込むのに使用するjsファイル
 *
 */

// viewportを最適化する
const windowSize = window.innerWidth;
const metalist = document.getElementsByTagName('meta');

for (let i = 0; i < metalist.length; i++) {
  const name = metalist[i].getAttribute('name');
  if (name && name.toLowerCase() === 'viewport') {
    // 375pxより小さいデバイスを判定
    if (windowSize < 375) {
      // viewportを375px固定し、表示領域を縮小させて表示させる
      metalist[i].setAttribute('content', 'width=375');
    }
  }
}
